import { errorToast } from "../../../utils/toast";
import * as actions from "./types";
import { postVerifyEsewaPaymentApi, postVerifyKhaltiPaymentApi } from "../../../apis/payment";

export const postEsewaVerifySuccessAction = (data) => (dispatch) => {
  dispatch({ type: actions.POST_ESEWA_VERIFY_BEGIN });
  postVerifyEsewaPaymentApi(data)
    .then((res) => {
      dispatch({ type: actions.POST_ESEWA_VERIFY_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: actions.POST_ESEWA_VERIFY_ERROR });
      errorToast(err);
    });
};

export const postKhaltiVerifySuccessAction = (data) => (dispatch) => {
  dispatch({ type: actions.POST_ESEWA_VERIFY_BEGIN });
  postVerifyKhaltiPaymentApi(data)
    .then((res) => {
      dispatch({ type: actions.POST_ESEWA_VERIFY_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: actions.POST_ESEWA_VERIFY_ERROR });
      errorToast(err);
    });
};
