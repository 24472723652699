import React from "react";

function SummaryWrapper({ wrapperTitle, children }) {
  return (
    <>
      <div className="summary-wrapper">
        <p>{wrapperTitle}</p>
        {children}
      </div>
    </>
  );
}

export default SummaryWrapper;
