import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FiClock, FiUser } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomModal from "../components/global/customModal";
import ForgotPassword from "../components/local/auth/ForgotPassword";
import Login from "../components/local/auth/Login";
import Logout from "../components/local/auth/Logout";
import Register from "../components/local/auth/Register";
import useToggle from "../hooks/useToggle";
import { isLoggedIn } from "../utils";

const Navbar = () => {
  const { siteSettingData } = useSelector((state) => state.siteSetting);
  const [siteSettingInfo, setSiteSettingInfo] = useState([]);
  const { userData } = useSelector((state) => state.profile);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    siteSettingData?.data && setSiteSettingInfo(siteSettingData?.data);
  }, [siteSettingData?.data]);

  useEffect(() => {
    userData && setUserInfo(userData);
  }, [userData]);

  const [registerOpen, registerToggle] = useToggle(false);
  const [loginOpen, loginToggle] = useToggle(false);
  const [forgotOpen, forgotToggle] = useToggle(false);
  const [logoutOpen, logoutToggle] = useToggle(false);

  const navigate = useNavigate();

  return (
    <div className="my-navbar">
      <div className="container">
        <div className="navbar-wrapper">
          <div className="logo-wrapper" onClick={() => navigate("/")}>
            <img src={siteSettingInfo?.logo} />
            <div className="company-name">{siteSettingInfo?.title}</div>
          </div>
          <div className="nav-buttons">
            {isLoggedIn() ? (
              <div className="profile-wrapper">
                <Dropdown>
                  <Box className="profile-box">
                    <Typography className="profile-text">
                      {userInfo?.username ? userInfo?.username : userInfo?.first_name}
                    </Typography>
                    <Dropdown.Toggle>
                      <Avatar
                        src={userInfo?.image}
                        alt=""
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          background: "#26BD8A",
                          color: "#fff"
                        }}>
                        {userInfo?.first_name
                          ? userInfo?.first_name?.charAt(0)
                          : userInfo?.username?.charAt(0)}
                      </Avatar>
                      {/* <img src={logo} alt="profile image" /> */}
                    </Dropdown.Toggle>
                  </Box>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate("/dashboard/profile")}>
                      <FiUser size={"16px"} /> <span className="drop-down-item">My Profile</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/dashboard/ticket")}>
                      <FiClock size={"16px"} /> <span className="drop-down-item">History</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logoutToggle}>
                      {" "}
                      <MdLogout size={"16px"} /> <span className="drop-down-item">Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <>
                <button className="register-btn" onClick={registerToggle}>
                  Signup
                </button>
                <button className="login-btn" onClick={loginToggle}>
                  Login
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        open={registerOpen}
        handleClose={registerToggle}
        modalClass="login-modal signup-modal">
        <Register handleOtherClose={loginToggle} handleClose={registerToggle} />
      </CustomModal>
      <CustomModal open={loginOpen} handleClose={loginToggle} modalClass="login-modal">
        <Login
          handleOtherClose={registerToggle}
          handleClose={loginToggle}
          forgotToggle={forgotToggle}
        />
      </CustomModal>
      <CustomModal open={forgotOpen} handleClose={forgotToggle} modalClass="login-modal">
        <ForgotPassword handleClose={forgotToggle} handleOtherClose={loginToggle} />
      </CustomModal>
      <CustomModal open={logoutOpen} handleClose={logoutToggle} modalClass="login-modal">
        <Logout handleClose={logoutToggle} />
      </CustomModal>
    </div>
  );
};

export default Navbar;
