import React from "react";
import PassengerIcon from "../../../assets/images/passenger.png";

function FormWrapper({ children, formTitle = "" }) {
  return (
    <div className="form-wrapper">
      <div className="title-container">
        <h3>{formTitle}</h3>
        <img src={PassengerIcon} alt={"Passenger Icon"} />
      </div>
      {children}
    </div>
  );
}

export default FormWrapper;
