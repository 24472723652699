/*eslint-disable*/
import { Box } from '@mui/material';
import React from 'react';
import ContactUs from '../components/local/contactUs';

const ContactUsPage = () => {
  return (
    <Box>
      <ContactUs/>
    </Box>
  )
}

export default ContactUsPage
