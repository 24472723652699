/* eslint-disable */
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import FlightClassIcon from "@mui/icons-material/FlightClass";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeFormat from "../../../global/timeFormat";
import { getBusSeats } from "../redux/actions";
import Collapse from "./Collapse";

function SearchTable() {
  const availableBus = useSelector((state) => state.search.buses?.data);
  const [show, setShow] = useState(null);
  console.log({ availableBus });

  // const handleShow = (id) => {
  //   if (show === id) {
  //     return setShow(null);
  //   }
  //   setShow(id);
  //   dispatch(getBusSeats(id));
  // };

  return (
    <div className="search-table">
      <div className="container">
        <div className="webTable">
          <table className="table table-condensed">
            <thead>
              <tr>
                <th width="350">Travels </th>
                <th width="200">Bus Facility</th>
                <th width="200">Bus No.</th>
                <th width="150">Departure / Shifts</th>
                <th width="100">Available</th>
                <th width="100">Price</th>
              </tr>
            </thead>
            <tbody>
              {availableBus?.map((item) => (
                <TableRow items={item} key={item.id} show={show} setShow={setShow} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="mobileTable">
          {availableBus?.map((item) => {
            return <MobileCardTable items={item} key={item.id} show={show} setShow={setShow} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default SearchTable;

const MobileCardTable = ({ items, show, setShow }) => {
  const dispatch = useDispatch();

  const handleShow = (id) => {
    if (show === id) {
      return setShow(null);
    }
    setShow(id);
    dispatch(getBusSeats(id));
  };
  return (
    <>
      <div
        className="mobileTableContainer"
        onClick={() => {
          handleShow(items?.id);
        }}>
        <Box className="mobileTableCard">
          <Box className="mobileTableInfoHeadBox">
            <Typography className="mobileTableHead">{items?.bus?.association?.name}</Typography>
          </Box>
          <Box className="mobileTableInfoBox">
            <Box className="mobileTableInfoItem">
              <DirectionsBusIcon className="mobileTableInfoIcon" />
              <Typography className="mobileTableInfoText">
                {items?.bus?.bus_facility_type?.name} ({items?.bus?.bus_no})
              </Typography>
            </Box>
            <Box className="mobileTableInfoItem">
              <AccessTimeIcon className="mobileTableInfoIcon" />
              <Typography className="mobileTableInfoText">
                <TimeFormat time={items?.departure_time} />{" "}
                <span style={{ color: "grey" }}> ({items?.shift})</span>
              </Typography>
            </Box>
            <Box className="mobileTableInfoItem">
              <FlightClassIcon className="mobileTableInfoIcon" />
              <Typography className="mobileTableInfoText">
                {`${items?.available ? items?.available : "dsa"} `} Seats
              </Typography>
            </Box>
          </Box>
          <hr />
          <Box className="mobileTablePriceBox">
            <Typography className="mobileTablePrice">
              Rs {items.route?.sub_route?.price || items?.route?.price}
            </Typography>
            <button className="mobileTablePriceNextBtn">Continue</button>
          </Box>
        </Box>
      </div>
      {show === items?.id && (
        // <tr>
        //   <td colSpan="12">
        <Collapse
          price={items?.route?.sub_route?.price || items?.route?.price}
          discount={items?.route?.sub_route?.discount || items?.route?.discount}
          items={items}
          key={items?.id}
        />
        //   </td>
        // </tr>
      )}
    </>
  );
};

const TableRow = ({ items, show, setShow }) => {
  const dispatch = useDispatch();
  console.log("searchBarItem", { items });

  const handleShow = (id) => {
    if (show === id) {
      return setShow(null);
    }
    setShow(id);
    dispatch(getBusSeats(id));
  };

  const { userData } = useSelector((state) => state.profile);

  const seatsInformation = useSelector((state) => state.search?.seats);
  const [seatsData, setSeatsData] = useState(seatsInformation?.data);
  console.log("searchBoxUserId", { seatsData });

  useEffect(() => {
    seatsInformation?.data && setSeatsData(seatsInformation?.data);
  }, [seatsInformation?.data]);

  return (
    <>
      <tr
        onClick={() => {
          handleShow(items?.id);
        }}
        className="tr-curved">
        <td>{items?.bus?.association?.name}</td>
        <td>{items?.bus?.bus_facility_type?.name}</td>
        <td>{items?.bus?.bus_no}</td>
        <td>
          <TimeFormat time={items?.departure_time} /> ({items?.shift})
        </td>
        <td>{`${items?.available ? items?.available : "0"} `} Seats</td>
        <td>Rs {items.route?.sub_route?.price || items?.route?.price}</td>
      </tr>
      {show === items?.id && (
        <tr>
          <td colSpan="12">
            <Collapse
              // price={
              //   (seatsData?.user_id === (userData?.id || "") && items?.route?.sub_route?.price) ||
              //   items?.route?.price
              // }
              // discount={
              //   (seatsData?.user_id === (userData?.id || "") &&
              //     items?.route?.sub_route?.discount) ||
              //   items?.route?.discount
              // }
              price={items?.route?.sub_route?.price || items?.route?.price}
              discount={items?.route?.sub_route?.discount || items?.route?.discount}
              items={items}
              key={items?.id}
            />
          </td>
        </tr>
      )}
    </>
  );
};
