/* eslint-disable */
import { Box, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SkeletonAboutImage from "../../global/loader/skeletonLoader/AboutImage";
import SkeletonArticle from "../../global/loader/skeletonLoader/SkeletonArticle";
import { getAssociationSingle } from "../home/redux/actions";
import AssocationHead from "./AssocationHead";

const Associations = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();
  const { slug } = useParams();

  const { association_single, association_single_loading } = useSelector((state) => state.home);
  const [associationSingleData, setAssociationSingleData] = useState();

  useEffect(() => {
    association_single?.data && setAssociationSingleData(association_single?.data);
  }, [association_single?.data]);

  useEffect(() => {
    const data = {
      slug: slug ?? ""
    };
    dispatch(getAssociationSingle(data));
  }, [slug]);

  const data = [
    { id: 1, title: "Head Office: kathmandu" },
    { id: 2, title: "Total number of bus offered: 12" },
    { id: 3, title: "Started Operating in: 2011 " },
    { id: 4, title: "Types of Bus Offered: Deluxe Bus, Hiace, Sumo & Car." },
    {
      id: 5,
      title:
        "Popular routes served: Ahmedabad to Bangalore, Bangalore to Pushkar, Mumbai to Mangalore, Bangalore to Rajkot, etc."
    }
  ];

  return (
    <>
      <Box className="profile-container-wrapper">
        {association_single_loading ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <h1>{associationSingleData?.name}</h1>
        )}
      </Box>

      <Box>
        {association_single_loading ? (
          <div className="container">
            <Grid container sx={{ marginTop: "80px" }}>
              <Grid item lg={6}>
                <SkeletonAboutImage asso />
              </Grid>
              <Grid item lg={6}>
                <SkeletonArticle />
              </Grid>
            </Grid>
          </div>
        ) : (
          <AssocationHead data={associationSingleData} />
        )}
      </Box>
      <Box>{/* <AssocationTable /> */}</Box>
    </>
  );
};

export default Associations;
