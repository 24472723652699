/*eslint-disable*/
import { Box } from '@mui/material';
import React from 'react';
import TermsAndConditions from '../components/local/termsAndConditions';

const TermsAndConditionsPage = () => {
  return (
    <Box>
      <TermsAndConditions/>
    </Box>
  )
}

export default TermsAndConditionsPage
