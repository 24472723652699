import googleImage from "../../../assets/images/google.png";
import facebookImage from "../../../assets/images/facebook.png";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

export const GoogleButton = ({ googleLoginHandler }) => {
  return (
    <button
      type="button"
      style={{ marginTop: "10px" }}
      className="google-button"
      onClick={() => googleLoginHandler()}>
      <img src={googleImage} alt="" />
      <span>Sign in with Google</span>
    </button>
  );
};

export const FacebookButton = ({ handleSocialLogin }) => {
  return (
    <FacebookLogin
      // appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
      appId="3370423926588469"
      autoLoad={false}
      fields="name,email,picture"
      callback={(response) => handleSocialLogin(response, "facebook")}
      render={(renderProps) => {
        return (
          <button
            type="button"
            style={{
              marginTop: "10px",
              backgroundColor: "#4267b2 !important",
              color: "white !important"
            }}
            className="google-button"
            onClick={renderProps.onClick}>
            <img src={facebookImage} alt="" />
            <span>Sign in with Facebook </span>
          </button>
        );
      }}
    />
  );
};

/* export const FacebookButton = ({ onClick }) => {
  return (
    <button
      type="button"
      style={{
        marginTop: "10px",
        backgroundColor: "#4267b2 !important",
        color: "white !important"
      }}
      className="google-button"
      onClick={onClick}>
      <img src={facebookImage} alt="" />
      <span>Sign in with Facebook </span>
    </button>
  );
}; */
