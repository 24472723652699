import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

function SearchableSelect({
  control,
  label = "",
  placeholder = "",
  type = "text",
  name,
  options,
  disabled = false,
  styles,
  errors
}) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Select
            styles={styles}
            options={options}
            onChange={(e) => onChange(e.value)}
            value={options?.filter((obj) => obj?.value === value)}
          />
        )}
      />
      {errors && errors[name] && <small className="error-text">{errors?.[name]?.message}</small>}
    </>
  );
}

export default SearchableSelect;
