/*eslint-disable*/
import { Box } from '@mui/material';
import React from 'react';
import AboutUs from '../components/local/aboutUs';

const AboutUsPage = () => {
  return (
    <Box>
      <AboutUs/>
    </Box>
  )
}

export default AboutUsPage
