import { getSiteSettingApi } from "../../apis";
// import { errorToast, successToast } from "../../utils/toast";
import * as actions from "./types";

export const getSiteSetting = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_SITE_SETTING_BEGIN });

  try {
    const { data } = await getSiteSettingApi(Data);
    dispatch({
      type: actions.GET_SITE_SETTING_SUCCESS,
      payload: data
    });
    // successToast(data.message);
  } catch (error) {
    dispatch({ type: actions.GET_SITE_SETTING_ERROR });
    // errorToast(error);
  }
};
