import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Description from "../../global/description/Description";
function Card({ data, slider }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/services/${data?.slug}`);
  };
  return (
    <Grid
      item
      xl={4}
      lg={4}
      md={4}
      sm={12}
      xs={12}
      sx={{ display: "flex", justifyContent: "center" }}>
      <div className="card-wrapper" onClick={handleNavigate}>
        {/* image */}
        <div className={slider ? "slider-image-container" : "image-container"}>
          <img
            src={data?.photo ?? data?.image}
            alt={`Book Our Bus`}
            className={slider ? "slider-card-image" : "card-image"}
          />
        </div>
        {/* title and bus type */}
        <div className="content-container">
          {data?.name && (
            <h2 className="card-title">
              {data?.name?.substring().length < 59
                ? data?.name
                : `${data?.name?.substring(0, 60)}...`}
            </h2>
          )}
          {data?.title && (
            <h2 className="card-title">
              {data?.title?.substring().length < 59
                ? data?.title
                : `${data?.title?.substring(0, 60)}...`}
            </h2>
          )}
          {/* <h3 className="card-subtitle">{data?.type}</h3> */}
          <p className="card-description">
            <Description description={data?.about ?? data?.description} />
          </p>
        </div>
      </div>
    </Grid>
  );
}

export default Card;
