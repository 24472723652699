/* eslint-disable */
import { combineReducers } from "redux";
import authReducer from "../components/local/auth/redux/reducers";
import contactReducer from "../components/local/contactUs/redux/reducers";
import bookingReducer from "../components/local/dashboard/HistoryPage/redux/reducers";
import profileReducer from "../components/local/dashboard/Profile/redux/reducers";
import homeReducer from "../components/local/home/redux/reducers";
import { seatBookingReducer } from "../components/local/search/components/redux/Reducer";
import searchReducer from "../components/local/search/redux/reducers";
import siteSettingReducer from "../layout/redux/reducers";
import paymentReducer from "../pages/dashboard/redux/reducers";

export default combineReducers({
  auth: authReducer,
  search: searchReducer,
  payment: paymentReducer,
  profile: profileReducer,
  seatBooking: seatBookingReducer,
  siteSetting: siteSettingReducer,
  home: homeReducer,
  contact: contactReducer,
  booking: bookingReducer
});
