import * as actions from "./Type";

const initialState = {
  seats: 0,
  count: [],
  seatsName: []
};
export const seatBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SEAT_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    // case actions.SEAT_IDENTIFICATION:
    //   return {
    //     ...state,
    //     seatIdentification: action.payload,
    //   };
   
    default:
      return state;
  }
};
