/* eslint-disable */

import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useToggle from "../../../../hooks/useToggle.js";
import { isLoggedIn } from "../../../../utils/index.js";
import CustomModal from "../../../global/customModal/index.js";
import Login from "../../auth/Login.js";
import BusSeats from "./BusSeats.js";
import { seatCount } from "./redux/Action.js";

function Collapse(props) {
  // const authState = useSelector((state) => state.auth);
  const [loginOpen, loginToggle] = useToggle(false);
  const { seat_loading: seatLoading } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [seats, setSeats] = useState([]);
  const [seatsName, setSeatsName] = useState([]);
  // const [seatData, setSeatData] = useState([]);

  const seatBooking = useSelector((state) => state.seatBooking);
  console.log("helocount", { seatBooking });

  useEffect(() => {
    setSeats(seatBooking?.seats);
  }, [seatBooking?.seats]);
  useEffect(() => {
    setCount(seatBooking?.count);
  }, [seatBooking?.count]);
  useEffect(() => {
    setSeatsName(seatBooking?.seatsName);
  }, [seatBooking?.seatsName]);

  const [percentage, setPercentage] = useState(null);
  // const [netAmount, setNetAmount] = useState(null);

  // const num1 = 2000;
  // const num2 = 98;

  useEffect(() => {
    const calculatePricePercentage = () => {
      if (props?.discount !== 0 || props?.discount !== null) {
        const calculateDifference = (props?.discount / props?.price) * 100;
        setPercentage(calculateDifference.toFixed(2));
      }
    };
    calculatePricePercentage();
  }, [props?.discount, props?.price]);

  const netAmount =
    props?.discount !== 0 || props?.discount !== null
      ? count * props?.price - count * props?.discount
      : count * props?.price;

  // const {

  //   setValue
  // } = useForm({});

  // useEffect(() => {
  //   if (location.state) {
  //     const strToIntLocation = {
  //       ...location?.state,

  //       date: location?.state.date
  //     };
  //     setValue("from", strToIntLocation.from);

  //   }
  // }, []);

  const PreButtonSubmit = () => {
    const data = {
      schedule_id: props.items.id,
      date: props.items.departure_date,
      seat_id: seats,
      from: props.items?.route?.sub_route?.from?.id || props.items.route.from.id,
      to: props.items?.route?.sub_route?.to?.id || props.items.route.to.id
    };
    const finalData = {
      seats,
      count,
      seatsName
    };
    navigate("/confirmation", { state: { ...data } });
    dispatch(seatCount(finalData));
  };

  return (
    <div className="container">
      {seatLoading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Spinner animation="grow" />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <BusSeats
              price={props.price}
              setCount={setCount}
              setSeats={setSeats}
              seats={seats}
              setSeatsName={setSeatsName}
              // setSeatData={setSeatData}
            />
          </div>
          <div className="col-md-12 col-lg-4 d-flex justify-content-center">
            <div className="booking-wrapper">
              <div className="destination">{`${props?.items?.route?.from?.city} - ${props?.items?.route?.to?.city}`}</div>
              <ul>
                <li>
                  <div className="title">Seats</div>
                  <div className="value">{seats?.length ? seatsName?.join(", ") : "-"}</div>
                  {/* <div className="value">{seatCount?.count}</div> */}
                </li>
                <li>
                  <div className="title">Amount</div>
                  <div>
                    <div className="value" style={{ fontWeight: 800 }}>
                      {/* Rs. {count * props?.price} */}
                      Rs. {netAmount}
                    </div>
                    {props?.discount && (
                      <div style={{ display: "flex", marginTop: "-15px" }}>
                        <div
                          className="value"
                          style={{
                            textDecoration: "line-through #f26f74",
                            color: "#f26f74",
                            fontSize: "13px"
                          }}>
                          Rs. {Number(seats?.length) ? Number(seats?.length) * props?.price : 0}
                        </div>
                        <div
                          className="value"
                          style={{ marginLeft: "10px", fontSize: "13px", color: "green" }}>
                          {percentage}% off
                        </div>
                      </div>
                    )}
                  </div>
                </li>
                {/* <li>
                  <div className="title" style={{ fontWeight: 800 }}>
                    Net Amount
                  </div>
                  <div className="value" style={{ fontWeight: 800 }}>
                    Rs. {netAmount}
                  </div>
                </li> */}
              </ul>
              {seats.length > 0 && isLoggedIn() && (
                <button onClick={PreButtonSubmit}>Continue Booking</button>

                // <Link
                //   to={"/confirmation"}
                //   state={{
                //     schedule_id: props?.items.id,
                //     date: props.items?.departure_date,
                //     seat_id: seats,
                //     from: props.items?.route?.sub_route?.from?.id || props.items.route.from.id,
                //     to: props.items?.route?.sub_route?.to?.id || props.items.route.to.id
                //   }}>
                //   <button onClick={PreButtonSubmit}>Continue Booking</button>
                // </Link>
              )}
              {seats.length === 0 && isLoggedIn() && (
                <button disabled style={{ background: "brown" }}>
                  Continue Booking
                </button>
              )}
              {!isLoggedIn() && <button onClick={loginToggle}>Log In To Continue Booking</button>}
            </div>
          </div>
        </div>
      )}
      <CustomModal open={loginOpen} handleClose={loginToggle} modalClass="login-modal">
        <Login
          // handleOtherClose={registerToggle}
          handleClose={loginToggle}
          // forgotToggle={forgotToggle}
        />
      </CustomModal>
    </div>
  );
}

export default Collapse;
