import "bootstrap/dist/css/bootstrap.min.css";
import "nepali-datepicker-reactjs/dist/index.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import { getUserDetail } from "./components/local/dashboard/Profile/redux/actions";
import { getLocation, getLocationMain } from "./components/local/search/redux/actions";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";
import { getSiteSetting } from "./layout/redux/actions";
import RouteList from "./routes";
import "./styles/globals.scss";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocation());
    dispatch(getSiteSetting());
    dispatch(getUserDetail());
    dispatch(getLocationMain());
  }, []);

  const { siteSettingData } = useSelector((state) => state.siteSetting);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = siteSettingData?.data?.logo;
  }, [siteSettingData?.data?.logo]);

  return (
    <>
      <Navbar />
      <div className="page-container">
        <RouteList />
      </div>
      <Footer />
      <ToastContainer autoClose={4000} limit={3} draggablePercent={50} />
    </>
  );
}

export default App;
