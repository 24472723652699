// import { Grid } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SuccessImg from "../../../../assets/images/Success.png";
import TicketShowcase from "./TicketShowcase";

function Ticket() {
  const { responseData } = useSelector((state) => state.payment);

  return (
    <div className="wrapper">
      {/* success and back to homepage btn */}
      <div className="ticket-container">
        <Grid container>
          {/* ticket success image container */}
          <Grid
            item
            lg={6}
            md={12}
            sx={{ marginBottom: "30px", display: "flex", justifyContent: "center" }}>
            <div className="ticket-success-container">
              <div className="ticket-success-img-box">
                <img
                  className="ticket-success-img"
                  src={SuccessImg}
                  alt={"Your payment is successfull.."}
                />
              </div>
              {responseData?.payment_status === "verification_success" && (
                <>
                  <h3>Congratulation! Your Payment is successfully completed. </h3>
                  <Link to={"/"}>
                    <button>Go back to homepage</button>
                  </Link>
                </>
              )}

              {responseData?.payment_status === "verification_failed" && (
                <>
                  <h3>Sorry, Your Payment Verification was failed. </h3>
                  <Link to={"/"}>
                    <button>Go back to homepage</button>
                  </Link>
                </>
              )}
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sx={{ marginBottom: "30px", display: "flex", justifyContent: "center" }}>
            {/* ticket showcase and download button */}
            <TicketShowcase
              showCaseTitle={"Bus Ticket"}
              from={
                responseData?.sub_route_price?.sub_route_from
                  ? responseData?.sub_route_price?.sub_route_from
                  : responseData?.schedule_id?.route?.from?.city
              }
              to={
                responseData?.sub_route_price?.sub_route_to
                  ? responseData?.sub_route_price?.sub_route_to
                  : responseData?.schedule_id?.route?.to?.city
              }
              date={responseData?.schedule_id?.departure_date}
              time={responseData?.schedule_id?.departure_time}
              name={responseData?.contact_name}
              travelName={responseData?.bus_details}
              route={`${
                responseData?.schedule_id?.route?.from?.city
                  ? responseData?.schedule_id?.route?.from?.city
                  : responseData?.sub_route_price?.sub_route_from
              } to ${
                responseData?.schedule_id?.route?.to?.city
                  ? responseData?.schedule_id?.route?.to?.city
                  : responseData?.sub_route_price?.sub_route_to
              }`}
              phoneNo={responseData?.contact_phone}
              seats={responseData?.seat_name}
              status={responseData?.status}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Ticket;
