import React from "react";

function SectionTitle({ title }) {
  return (
    <>
      <h1 className="section-title">{title}</h1>
    </>
  );
}

export default SectionTitle;
