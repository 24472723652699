import { getMyBookingApi } from "../../../../../apis/search";
import * as actions from "./types";

// get my booking

// export const getMyBooking = (Data) => async (dispatch) => {
//   dispatch({ type: actions.GET_MY_BOOKING_BEGIN });

//   try {
//     const { data } = await getMyBookingApi(Data);
//     dispatch({ type: actions.GET_MY_BOOKING_SUCCESS, payload: data });
//   } catch (error) {
//     dispatch({ type: actions.GET_MY_BOOKING_ERROR });
//   }
// };

export const getMyBooking = (from_date, to_date) => async (dispatch) => {
  dispatch({ type: actions.GET_MY_BOOKING_BEGIN });

  // try {
  //   const { data } = await getMyBookingApi(Data);
  //   dispatch({
  //     type: actions.GET_MY_BOOKING_SUCCESS,
  //     payload: data
  //   });
  // } catch (error) {
  //   dispatch({ type: actions.GET_MY_BOOKING_ERROR });
  // }
  getMyBookingApi(from_date, to_date)
    .then((res) => {
      dispatch({
        type: actions.GET_MY_BOOKING_SUCCESS,
        payload: res?.data?.data
      });
    })
    .catch((err) => {
      dispatch({
        type: actions.GET_MY_BOOKING_ERROR
      });
    });
};
