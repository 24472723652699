import { format } from "date-fns";
import { useEffect, useState } from "react";

const TimeFormat = ({ time }) => {
  const [time24, setTime24] = useState("");
  const [time12, setTime12] = useState("");

  useEffect(() => {
    if (time) {
      setTime24(time);
    }
  }, [time]);

  useEffect(() => {
    if (time24) {
      setTime12(convertTo12HourFormat(time24));
    }
  }, [time24]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const date = new Date(0, 0, 0, hours, minutes);
    const formattedTime = format(date, "h:mm a");
    return formattedTime;
  };
  return <>{time12}</>;
};

export default TimeFormat;
