import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import KhaltiImg from "../assets/images/khalti.png";
import Description from "../components/global/description/Description";
// import Logo from "../assets/images/logo.png";

const FooterLinks = ({ link, title }) => {
  return (
    <Link to={link} className={"link"}>
      <span className="footer-link">{title} </span>
    </Link>
  );
};

function Footer() {
  const { siteSettingData } = useSelector((state) => state.siteSetting);
  const [siteSettingInfo, setSiteSettingInfo] = useState([]);

  useEffect(() => {
    siteSettingData?.data && setSiteSettingInfo(siteSettingData?.data);
  }, [siteSettingData?.data]);

  const navigate = useNavigate();
  const logoNavigate = () => {
    navigate(`/`);
  };
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <Grid container>
          <Grid item lg={5} md={5} sm={12} xs={12} className="footerG">
            <div className="slogan-container">
              <img
                src={siteSettingInfo?.logo}
                // alt={"Logo"}
                className={"footer-logo"}
                onClick={logoNavigate}
                style={{ cursor: "pointer" }}
              />
              <p className="footer-slogan">{siteSettingInfo?.subtitle} </p>
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12} className="footerG2">
            <div>
              <h4 className="footer-title">Support</h4>
              <div className="support-container">
                <FooterLinks link={`/about-us`} title={"About Us"} />
                <FooterLinks link={`/contact-us`} title={"Contact Us"} />
                <FooterLinks link={`/terms-and-conditions`} title={"Terms & Conditions"} />
                <FooterLinks link={`/privacy-policy`} title={"Privacy Policy"} />
                {/* <FooterLinks link={`/why-bus-sewa`} title={"Why Bus Sewa?"} /> */}
              </div>
            </div>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12} className="footerG">
            <div>
              <h4 className="footer-title">Our payment partners</h4>
              <Grid container>
                <Grid item lg={6} md={6} sm={6} xs={6} className="payment-icon-box">
                  <img src={KhaltiImg} alt="" className="payment-icon" />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

      <hr />
      <div className="copyright-container text-center">
        <Description description={siteSettingInfo?.copyright} />
      </div>
    </div>
  );
}

export default Footer;
