import AboutUsPage from "../../pages/AboutUsPage";
import AssociationPage from "../../pages/AssociationPage";
import ContactUsPage from "../../pages/ContactUsPage";
import ForgotPage from "../../pages/ForgotPage";
import HomePage from "../../pages/HomePage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import SearchPage from "../../pages/SearchPage";
import ServicesPage from "../../pages/ServicesPage";
import TermsAndConditionsPage from "../../pages/TermsAndConditionsPage";
import VerificationPage from "../../pages/verificationPage/VerificationPage";
import WhyBusSewaPage from "../../pages/WhyBusSewaPage";

const HomeRoutes = [
  {
    path: "/",
    component: <HomePage />
  },
  { path: "/search", component: <SearchPage /> },
  { path: "/forgot-password", component: <ForgotPage /> },
  {
    path: "/account-verification-email",
    component: <VerificationPage />
  },
  {
    path: "/forget-password",
    component: <ResetPasswordPage />
  },
  {
    path: "/about-us",
    component: <AboutUsPage />
  },
  {
    path: "/contact-us",
    component: <ContactUsPage />
  },
  {
    path: "/terms-and-conditions",
    component: <TermsAndConditionsPage />
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicyPage />
  },
  {
    path: "/why-bus-sewa",
    component: <WhyBusSewaPage />
  },
  {
    path: "/association/:slug",
    component: <AssociationPage />
  },
  {
    path: "/services/:slug",
    component: <ServicesPage />
  }
];

export default HomeRoutes;
