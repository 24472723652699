import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Ticket from "../../components/local/dashboard/TicketInfo/Ticket";
import { postKhaltiVerifySuccessAction } from "./redux/actions";

// import { postEsewaVerifySuccessAction, postKhaltiVerifySuccessAction } from "./redux/actions";

function TicketInfoPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.payment);
  const Navigate = useNavigate();
  const { bookingId } = useParams();

  useEffect(() => {
    if (location.search) {
      const locationSearch = Object.fromEntries(
        location.search
          ?.replace("?", "")
          ?.split("&")
          ?.map((obj) => {
            const newObj = obj.split("=");
            return newObj;
          })
      );
      // dispatch(postEsewaVerifySuccessAction(locationSearch));
      if (locationSearch?.transaction_id) {
        dispatch(
          postKhaltiVerifySuccessAction({
            transaction_id: locationSearch?.transaction_id,
            amount: locationSearch?.amount,
            mobile: locationSearch?.mobile,
            purchase_order_id: locationSearch?.purchase_order_id,
            purchase_order_name: locationSearch?.purchase_order_name,
            pidx: locationSearch?.pidx,
            txnId: locationSearch?.txnId
          })
        );
      } else if (locationSearch.message || locationSearch["?message"]) {
        Navigate(`/payment/failed/${bookingId}`);
      }
    }
  }, [location]);

  return (
    <>
      {/* {isLoading && <p>Loading.....</p>} */}
      {isLoading && (
        <Box>
          <CircularProgress
            size="30px"
            thickness={6}
            style={{
              color: "#4C4B63",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              margin: "auto",
              height: "100vh",
              fontWeight: "bold"
            }}
          />
        </Box>
      )}
      {!isLoading && <Ticket />}
    </>
  );
}

export default TicketInfoPage;
