export const REGISTRATION_BEGIN = "REGISTRATION_BEGIN";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";

export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const FORGOT_PASSWORD_BEGIN = "FORGOT_PASSWORD_BEGIN";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_BEGIN = "RESET_PASSWORD_BEGIN";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const SET_AUTH_USER = "SET_AUTH_USER";

export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_ERROR = "VERIFICATION_ERROR";
export const VERIFICATION_PENDING = "VERIFICATION_PENDING";

export const RESET_VERIFY_PASSWORD_BEGIN = "RESET_VERIFY_PASSWORD_BEGIN";
export const RESET_VERIFY_PASSWORD_SUCCESS = "RESET_VERIFY_PASSWORD_SUCCESS";
export const RESET_VERIFY_PASSWORD_ERROR = "RESET_VERIFY_PASSWORD_ERROR";
