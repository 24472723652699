import React from "react";
import { Controller } from "react-hook-form";

function CustomInput({
  control,
  label = "",
  placeholder = "",
  type = "text",
  name,
  disabled = false,
  require = false,
  errors,
}) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <div className="input-display">
            <label className="form-label">{label}</label>
            {require ? <span className="required-label">*</span> : null}
            <input
              disabled={disabled}
              name={name}
              className="form-control"
              type={type}
              placeholder={placeholder}
              onChange={onChange}
              value={value}

              // required={require}
            />
            {errors && errors[name] && (
              <small className="error-text">{errors?.[name]?.message}</small>
            )}
          </div>
        )}
      />
    </>
  );
}

export default CustomInput;
