import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { ADToBS } from "bikram-sambat-js";
import React from "react";
import { Controller } from "react-hook-form";
import { GoCalendar } from "react-icons/go";

function CustomNepaliDatePicker({
  control,
  label = "",
  placeholder = "",
  type = "",
  name,
  disabled = false,
  require,
  errors,
  isStyled = false,
  isIcon = false,
  minDate,
  defaultDate,
  newDateBS
}) {
  const todayDate = new Date();
  const date = todayDate.setDate(todayDate.getDate() - 1);
  const todayDateAD = new Date();
  const dateAD = todayDateAD.setDate(todayDateAD.getDate());
  // console.log({ todayDate})

  const newDate = ADToBS(todayDate);
  // console.log({ date, todayDate, newDate });

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <div className="date-picker-parent">
            {/* {console.log("dsadaqsw", { value })} */}
            <Calendar
              inputClassName="form-control"
              className={isStyled ? "date-picker" : ""}
              onChange={(value) => onChange(value?.bsDate)}
              minDate={newDate}
              language="en"
              type={type}
              // value="2080/01/23"
              defaultDate={defaultDate ? defaultDate : newDateBS}
            />

            {isIcon && (
              <div className="date-picker-icon">
                <GoCalendar size={"18px"} color={"#6C6B80"} />
              </div>
            )}
          </div>
        )}
      />
      {errors && errors[name] && <small className="error-text">{errors?.[name]?.message}</small>}
    </>
  );
}

export default CustomNepaliDatePicker;
