import { TableContainer } from "@mui/material";
import { ADToBS } from "bikram-sambat-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomNepaliDatePickerHistory from "../../../form/CustomNepaliDatePickerHistory/CustomNepaliDatePickerHistory";
import CustomModal from "../../../global/customModal/index";
import TicketShowcase from "../TicketInfo/TicketShowcase";
// import DatePicker from "./DatePicker";
import { getMyBooking } from "./redux/actions";

function HistoryTable() {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [booking, setBooking] = useState([]);
  const [items, setSingleBooking] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const { myBooking, myBooking_loading } = useSelector((state) => state.booking);
  //   const handleShow = () => {
  //     setOpen(!isOpen);
  //   };

  useEffect(() => {
    myBooking?.data && setBooking(myBooking?.data);
  }, [myBooking?.data]);

  useEffect(() => {
    // setIsLoading(true);
    // getMyBookingApi()
    //   .then((res) => {
    //     setBooking(res.data);
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //   });
    // const from_date = "2080-02-31";
    // const to_date = "2080-03-05";
    dispatch(getMyBooking());
  }, []);

  const showCase = (data) => {
    setSingleBooking(data);
  };

  // const schema = yup.object({
  //   from: yup.string().required("Origin is required"),
  //   to: yup.string().required("Destination is required."),
  //   date: yup.string().required("Travel date is required")
  // });

  // const resolver = useYupValidationResolver(schema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
    // setValue
  } = useForm({});

  // const dispatch = useDispatch();
  const submit = (values) => {
    // dispatch(searchBus(values));

    // const data = {
    const from_date = values?.from_date;
    const to_date = values?.to_date;
    // };
    dispatch(getMyBooking(from_date, to_date));
    // alert("filtered");
  };

  return (
    <>
      {/* <DatePicker /> */}
      <form onSubmit={handleSubmit(submit)}>
        <div className="date-container">
          <div className="picker-container">
            <label>Departure from</label>
            {/* <CustomNepaliPicker control={control} name={"fromDate"} isStyled={true} isIcon={true} /> */}
            <CustomNepaliDatePickerHistory
              type="date"
              name={"from_date"}
              control={control}
              errors={errors}
              isIcon={true}
            />
          </div>

          <span className="date-dash">-</span>

          <div className="picker-container">
            <label>Departure to</label>
            {/* <CustomNepaliPicker control={control} name={"toDate"} isStyled={true} isIcon={true} /> */}
            <CustomNepaliDatePickerHistory
              control={control}
              name={"to_date"}
              isStyled={true}
              isIcon={true}
              errros={errors}
            />
          </div>
          <button type="submit" className="history-filter-btn">
            Filter
          </button>
        </div>
      </form>
      <TableContainer style={{ width: "100%" }}>
        <div className="history-table">
          <table>
            <thead>
              <tr>
                <th>SN</th>
                <th>Booked date</th>
                <th>Departure date</th>
                <th width="200">Route</th>
                <th>Dropping point</th>
                {/* <th>Status type</th> */}
                <th>Seats</th>
                <th>Ticket no</th>
                <th>Amount</th>
              </tr>
            </thead>

            {myBooking_loading && (
              <tbody
                style={{
                  position: "relative",
                  height: "300px",
                  width: "100%",
                  background: "transparent"
                }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "100%",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)"
                  }}>
                  <Spinner animation="border" />
                </div>
              </tbody>
            )}

            {!myBooking_loading && (
              <tbody>
                {booking?.map((bookings, index) => {
                  return (
                    <TableRow
                      key={bookings.id}
                      items={bookings}
                      index={index + 1}
                      handleOpen={setOpen}
                      showCase={showCase}
                    />
                  );
                })}
              </tbody>
            )}
          </table>

          {isOpen && (
            <CustomModal
              open={isOpen}
              handleClose={() => setOpen(false)}
              modalClass={"secondary-modal"}>
              <div className="ticket-container">
                <TicketShowcase
                  showCaseTitle={"Bus Ticket"}
                  from={
                    items?.sub_route_price
                      ? items?.sub_route_price?.sub_route_from
                      : items?.schedule_id?.route?.from?.city
                  }
                  to={
                    items?.sub_route_price
                      ? items?.sub_route_price?.sub_route_to
                      : items?.schedule_id?.route?.to?.city
                  }
                  date={items?.schedule_id?.departure_date}
                  time={items?.schedule_id?.departure_time}
                  seats={items?.seat_name}
                  name={items?.contact_name}
                  phoneNo={items?.contact_phone}
                  route={`${
                    items?.schedule_id?.route?.from?.city
                      ? items?.schedule_id?.route?.from?.city
                      : items?.sub_route_price?.sub_route_from
                  } to ${
                    items?.schedule_id?.route?.to?.city
                      ? items?.schedule_id?.route?.to?.city
                      : items?.sub_route_price?.sub_route_to
                  }`}
                  travelName={items?.schedule_id?.bus?.company?.company_name}
                  status={items?.status}
                />
              </div>
            </CustomModal>
          )}
        </div>
      </TableContainer>
    </>
  );
}

export default HistoryTable;

const TableRow = ({ handleOpen, items, index, showCase }) => {
  //   const [show, setShow] = useState(false);
  const handleShow = () => {
    handleOpen((prev) => !prev);
    showCase(items);
  };
  return (
    <>
      <tr onClick={() => handleShow()}>
        <td className="table-data-bold">{index}</td>
        <td className="table-data-bold">{ADToBS(items?.created_at)}</td>
        <td className="table-data-bold">{items?.schedule_id?.departure_date}</td>
        <td>
          {items?.sub_route_price
            ? items?.sub_route_price?.sub_route_from
            : items?.schedule_id?.route?.from?.city}{" "}
          -{" "}
          {items?.sub_route_price
            ? items?.sub_route_price?.sub_route_to
            : items?.schedule_id?.route?.to?.city}
        </td>
        <td>{items?.drop_location}</td>
        <td className="table-data-bold">{items.qty} Seats</td>
        <td className="table-data-link">{items.ticket_code}</td>
        <td className="table-data-bolder">Rs {items.total}</td>
      </tr>
      {/* {show && (
        <tr>
          <td colSpan="12">
            <Collapse />
          </td>
        </tr>
      )} */}
    </>
  );
};
