/* eslint-disable prettier/prettier */
/* disable-eslint */

import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EsewaImg from "../../../assets/images/esewa.png";
import KhaltiImg from "../../../assets/images/khalti.png";
import BackBtn from "../../form/CustomButton/BackBtn";
import CustomPaymentRadio from "../../form/CustomSelect/CustomPaymentRadio";
import { payThroughKhalti, saveAllCustomerDetails } from "../search/redux/actions";
import FormWrapper from "./FormWrapper";
import PassengerDetail from "./PassengerDetail";

function PassengerDetails(props) {
  const [state, setState] = useState(null);
  const dispatch = useDispatch();

  const frontUrl = window?.location?.origin;

  const { customer_loading } = useSelector((state) => state.search);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("shadowEye"));
    setState(data);
  }, []);

  const SubmitHandler = (e) => {
    e.preventDefault();

    const newSeat = props.pre_booked_seat?.map((seat) => {
      return seat.seat_id.toString();
    });

    const finalData = {
      schedule_id: props.schedule.id,
      route_id: props.schedule.route.id,
      drop_location: state.drop_location,
      rate: props.schedule?.route?.sub_route?.price || props.schedule.route.price,
      qty: props.pre_booked_seat.length,
      discount: props?.schedule?.route?.sub_route?.discount
        ? props?.schedule?.route?.sub_route?.discount * props?.pre_booked_seat?.length
        : props?.schedule?.route?.discount * props?.pre_booked_seat?.length,
      t_price: props.schedule?.route?.sub_route?.price
        ? props.schedule?.route?.sub_route?.price * props.pre_booked_seat.length
        : props.schedule.route.price * props.pre_booked_seat.length,
      total: props.schedule?.route?.sub_route?.price
        ? props.schedule?.route?.sub_route?.price * props.pre_booked_seat.length -
          props?.schedule?.route?.sub_route?.discount * props?.pre_booked_seat?.length
        : props.schedule.route.price * props.pre_booked_seat.length -
          props?.schedule?.route?.discount * props?.pre_booked_seat?.length,
      // discount:
      //   props?.schedule?.route?.sub_route?.discount * props?.pre_booked_seat?.length ||
      //   props?.schedule?.route?.discount * props?.pre_booked_seat?.length,
      contact_name: state.name_of_passenger,
      contact_phone: state.mobile_number,
      paid: "",
      due: "",
      return: "",
      bus_details: props.schedule.bus.bus_type.name ?? [],
      seat_id: newSeat,
      // sub_route: [],
      sub_route_price: {
        from_id: props.schedule?.route?.sub_route?.from?.id
          ? props.schedule?.route?.sub_route?.from?.id
          : "",
        to_id: props.schedule?.route?.sub_route?.to?.id
          ? props.schedule?.route?.sub_route?.to?.id
          : "",
        sub_route_from: props.schedule?.route?.sub_route?.from?.city
          ? props.schedule?.route?.sub_route?.from?.city
          : "",
        sub_route_to: props.schedule?.route?.sub_route?.to?.city
          ? props.schedule?.route?.sub_route?.to?.city
          : "",
        price: props.schedule?.route?.sub_route?.price
          ? props.schedule?.route?.sub_route?.price * props.pre_booked_seat.length -
            props?.schedule?.route?.sub_route?.discount * props?.pre_booked_seat?.length
          : "",
        discount: props.schedule?.route?.sub_route?.discount
          ? props.schedule?.route?.sub_route?.discount * props.pre_booked_seat.length
          : ""
      },
      payment_status: "pending",
      status: "OnBooking"
    };

    const getData = (data) => {
      const bookingData = {
        bookingId: data.data
      };

      // EsewaPaymentApi(bookingData);
      dispatch(
        payThroughKhalti({
          // return_url: `http://localhost:3001/ticket-info/${bookingData.bookingId}`,
          // return_url: `https://buspark.vercel.app/ticket-info/${bookingData.bookingId}`,
          return_url: `${frontUrl}/ticket-info/${bookingData.bookingId}`,
          // website_url: "http://localhost:3000",
          website_url: `${frontUrl}/ticket-info`,
          amount: props.schedule?.route?.sub_route?.price
            ? props.schedule?.route?.sub_route?.price * props.pre_booked_seat.length -
              props?.schedule?.route?.sub_route?.discount * props?.pre_booked_seat?.length
            : props.schedule.route.price * props.pre_booked_seat.length -
              props?.schedule?.route?.discount * props?.pre_booked_seat?.length,
          // amount:
          //   props.schedule?.route?.sub_route?.price * props.pre_booked_seat.length ||
          //   props.schedule.route.price * props.pre_booked_seat.length,
          purchase_order_id: bookingData?.bookingId,
          purchase_order_name: state?.name_of_passenger,
          modes: ["KHALTI"]
        })
      );
    };

    dispatch(saveAllCustomerDetails(finalData, getData));

    /* const EsewaPaymentApi = (bookingData) => {
      var path = "https://uat.esewa.com.np/epay/main";
      var params = {
        amt:
          props.schedule?.route?.sub_route?.price * props.pre_booked_seat.length ||
          props.schedule.route.price * props.pre_booked_seat.length,
        psc: 0,
        pdc: 0,
        txAmt: 0,
        tAmt:
          props.schedule?.route?.sub_route?.price * props.pre_booked_seat.length ||
          props.schedule.route.price * props.pre_booked_seat.length,
        pid: bookingData.bookingId,
        scd: "EPAYTEST",
        su: "https://buspark.vercel.app/ticket-info",
        fu: `https://buspark.vercel.app/payment/failed/?bookId=${bookingData.bookingId}`
      };

      function post(path, params) {
        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", path);

        for (var key in params) {
          var hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("name", key);
          hiddenField.setAttribute("value", params[key]);
          form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form.submit();
      }
      post(path, params);
    }; */
  };

  return (
    <>
      <FormWrapper formTitle="Passenger Details">
        <div className="form-input-container">
          <PassengerDetail label={"Name of passenger"} labelValue={state?.name_of_passenger} />
          <PassengerDetail label={"Email Address"} labelValue={state?.email_address} />
          <div className="inputs-container">
            <PassengerDetail label={"Mobile Number"} labelValue={state?.mobile_number} />
            <PassengerDetail label={"Drop Location"} labelValue={state?.drop_location} />
          </div>
          <hr />
          <PassengerDetail label={"Payment Method"} />
          {state?.payment_method === "esewa" && (
            <CustomPaymentRadio isClicked={true} paymentMethodName={"esewa"} logo={EsewaImg} />
          )}
          {state?.payment_method === "khalti" && (
            <CustomPaymentRadio logo={KhaltiImg} name={"khalti"} isClicked={true} />
          )}

          {/* <CustomPaymentRadio logo={KhaltiImg} name={"khalti"} isClicked={true} /> */}
          {/* procced to checkout */}
          <button className="filled-btn" onClick={SubmitHandler}>
            {customer_loading ? (
              <CircularProgress
                size="27px"
                style={{ color: "#fff", display: "flex", alignItems: "center", margin: "auto" }}
              />
            ) : (
              "Proceed to Check"
            )}
          </button>
          <BackBtn />
        </div>
      </FormWrapper>
    </>
  );
}

export default PassengerDetails;
