import React from 'react';
import ResetPassword from '../components/local/auth/ResetPassword';

function ResetPasswordPage() {
  // return <div>ForgotPage</div>;
  return (
    <ResetPassword />
  )
}

export default ResetPasswordPage;
