import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";

function CustomPaymentRadio({
  logo,
  paymentMethodName,
  isClicked,
  label = "",
  type = "button",
  onClick
}) {
  return (
    <>
      <button name={paymentMethodName} className={"custom-btn"} type={type} onClick={onClick}>
        <img src={logo} />
        {isClicked && (
          <div className="checkmark-container">
            <IoIosCheckmarkCircle color="#61BC47" size={"16px"} />
          </div>
        )}
      </button>
    </>
  );
}

export default CustomPaymentRadio;
