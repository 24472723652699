import React from "react";
import { useNavigate } from "react-router-dom";
function BackBtn() {
  const Navigate = useNavigate();

  return (
    <div onClick={() => Navigate(-1)} className="btn-label">
      <p style={{marginTop: '15px'}}>Go back</p>
    </div>
  );
}

export default BackBtn;
