import React from "react";
// import DatePicker from "./DatePicker";
import HistoryTable from "./HistoryTable";

function History() {
  return (
    <>
      <div className="wrapper">
        {/* <DatePicker /> */}
        <HistoryTable />
      </div>
    </>
  );
}

export default History;
