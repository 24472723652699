import React from "react";
import { Modal } from "react-bootstrap";

function CustomModal({ open, handleClose, modalClass = "my-modal", children, style }) {
  return (
    <Modal show={open} onHide={handleClose} centered dialogClassName={modalClass} style={style}>
      {children}
    </Modal>
  );
}

export default CustomModal;
