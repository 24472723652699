import React, { useState } from "react";
import { useForm } from "react-hook-form";
import BackBtn from "../../form/CustomButton/BackBtn";
import FilledButton from "../../form/CustomButton/FilledButton";
import CustomInput from "../../form/CustomInput/CustomInput";
// import EsewaImg from "../../../assets/images/esewa.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import KhaltiImg from "../../../assets/images/khalti.png";
import useYupValidationResolver from "../../../hooks/useYupValidationResolver";
import CustomPaymentRadio from "../../form/CustomSelect/CustomPaymentRadio";
import { getUserDetail } from "./Profile/redux/actions";
// import { getUserDetail } from "./Profile/redux/actions";

let isFirst = true;

function ConfirmationForm(props) {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.profile);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    userData && setUserInfo(userData);
  }, [userData]);

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  const schema = yup.object({
    name_of_passenger: yup.string().required("Name of the passenger is required."),
    email_address: yup.string().email().required("Email field is required."),
    mobile_number: yup.string().required("Mobile Number is required."),
    drop_location: yup.string().required("Drop Location is required."),
    payment_method: yup.string().required()
  });

  const resolver = useYupValidationResolver(schema);

  const defaultValues = {
    name_of_passenger: "",
    email_address: "",
    mobile_number: "",
    drop_location: "",
    payment_method: ""
  };
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit
  } = useForm({ resolver, defaultValues });

  useEffect(() => {
    if (userInfo) {
      setValue("name_of_passenger", `${userInfo?.first_name} ${userInfo?.last_name}`);
      setValue("email_address", userInfo?.email);
      setValue("mobile_number", userInfo?.phone);
      setValue("drop_location", "");
      // if no option is selected place khalti as payment_method
      setValue("payment_method", userInfo?.payment_method || "khalti");
    }
  }, [userInfo]);
  const watchAll = watch();

  const location = useLocation();

  const Navigate = useNavigate();
  const state = location.state;

  // useEffect(() => {
  //   if (!localStorage.getItem("shadowEye")) {
  //     localStorage.setItem("shadowEye", JSON.stringify(defaultValues));
  //     return;
  //   } else {
  //     let newData = JSON.parse(localStorage.getItem("shadowEye"));
  //     setValue("name_of_passenger", newData?.name_of_passenger);
  //     setValue("email_address", newData?.email_address);
  //     setValue("mobile_number", newData?.mobile_number);
  //     setValue("drop_location", newData?.drop_location);
  //     // if no option is selected place khalti as payment_method
  //     setValue("payment_method", newData?.payment_method || "khalti");
  //   }
  // }, []);

  useEffect(() => {
    if (isFirst) {
      isFirst = false;
      return;
    }
    localStorage.setItem("shadowEye", JSON.stringify(watchAll));
  }, [watchAll]);

  const submitHandler = (data) => {
    Navigate("/checkout", {
      replace: true,

      state: {
        schedule_id: state?.schedule_id,
        seat_id: state?.seat_id,
        date: state?.date,
        from: state?.from,
        to: state?.to
      }
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="form-input-container">
          <CustomInput
            name={"name_of_passenger"}
            require={true}
            control={control}
            label={"name of the passenger"}
            errors={errors}
          />
          <CustomInput
            name={"email_address"}
            type="email"
            control={control}
            label={"email address"}
            errors={errors}
          />

          <div className="inputs-container">
            <CustomInput
              name={"mobile_number"}
              require
              control={control}
              label={"mobile number"}
              errors={errors}
              type="number"
            />

            <CustomInput
              name={"drop_location"}
              require
              control={control}
              label={"drop location"}
              errors={errors}
            />
          </div>

          <p className={"form-labels"}>Payment Methods</p>
          <div className="radio-groups">
            {/* <CustomPaymentRadio
              logo={EsewaImg}
              name={"esewa"}
              isClicked={watch("payment_method") === "esewa" ? true : false}
              onClick={() => setValue("payment_method", "esewa")}
            />
            <CustomPaymentRadio
              logo={KhaltiImg}
              name={"khalti"}
              isClicked={watch("payment_method") === "khalti" ? true : false}
              onClick={() => setValue("payment_method", "khalti")}
            /> */}
            <CustomPaymentRadio
              logo={KhaltiImg}
              name={"khalti"}
              isClicked={true}
              onClick={() => setValue("payment_method", "khalti")}
            />
          </div>
          <div className="btn-groups">
            <FilledButton
              btnLabel={"Procced to confirmation"}
              // linkPath={"/checkout"}
              state={{
                schedule_id: state?.schedule_id,
                seat_id: state?.seat_id,
                date: state?.date,
                from: state?.from,
                to: state?.to
              }}
              type="submit"
            />
            <BackBtn />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ConfirmationForm;
