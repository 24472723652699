import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonAboutText() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}

      <Skeleton variant="rectangular" width={600} height={300} />

      {/* <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" /> */}
    </Stack>
  );
}
