import * as actions from "./types";

const initialState = {
  myBooking: [],
  myBooking_loading: false
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_MY_BOOKING_BEGIN:
      return { ...state, myBooking_loading: true };

    case actions.GET_MY_BOOKING_SUCCESS:
      return {
        ...state,
        myBooking_loading: false,
        myBooking: action.payload
      };

    case actions.GET_MY_BOOKING_ERROR:
      return { ...state, myBooking_loading: false };

    default:
      return state;
  }
};

export default bookingReducer;
