import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

import { isLoggedIn } from "../../../utils";

const Protected = ({ children }) => {
  if (isLoggedIn()) {
    return children;
  }
  return <Navigate to="/" replace />;
};

export default Protected;

Protected.propTypes = {
  children: PropTypes.element
};
