import * as actions from "./types";

const initialState = {
   siteSettingData: {},
   siteSetting_loading: false,
 };
 
 const siteSettingReducer = (state = initialState, action) => {
   switch (action.type) {
     case actions.GET_SITE_SETTING_BEGIN:
       return { ...state, siteSetting_loading: true };
 
     case actions.GET_SITE_SETTING_SUCCESS:
       return {
         ...state,
         siteSetting_loading: false,
         siteSettingData: action.payload,
       };
 
     case actions.GET_SITE_SETTING_ERROR:
       return { ...state, siteSetting_loading: false };
 
     default:
       return state;
   }
 };

 export default siteSettingReducer;
