import React from "react";
import History from "../../components/local/dashboard/HistoryPage/History";

function TicketPage() {
  return (
    <>
      <div className="profile-container-wrapper">
        <h1>History</h1>
      </div>

      <History />
    </>
  );
}

export default TicketPage;
