export const GET_LOCATIONS_BEGIN = "GET_LOCATIONS_BEGIN";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_ERROR = "GET_LOCATIONS_ERROR";

export const GET_LOCATIONS_MAIN_BEGIN = "GET_LOCATIONS_MAIN_BEGIN";
export const GET_LOCATIONS_MAIN_SUCCESS = "GET_LOCATIONS_MAIN_SUCCESS";
export const GET_LOCATIONS_MAIN_ERROR = "GET_LOCATIONS_MAIN_ERROR";

export const SEARCH_BUS_BEGIN = "SEARCH_BUS_BEGIN";
export const SEARCH_BUS_SUCCESS = "SEARCH_BUS_SUCCESS";
export const SEARCH_BUS_ERROR = "SEARCH_BUS_ERROR";

export const GET_SEATS_BEGIN = "GET_SEATS_BEGIN";
export const GET_SEATS_SUCCESS = "GET_SEATS_SUCCESS";
export const GET_SEATS_ERROR = "GET_SEATS_ERROR";

export const SELECT_SEAT_BEGIN = "SELECT_SEAT_BEGIN";
export const SELECT_SEAT_SUCCESS = "SELECT_SEAT_SUCCESS";
export const SELECT_SEAT_ERROR = "SELECT_SEAT_ERROR";

export const CHANGE_BOOKINGS_STATUS = "CHANGE_BOOKING_STATUS";

export const POST_SEAT_BEGIN = "POST_SEAT_BEGIN";
export const POST_SEAT_SUCCESS = "POST_SEAT_SUCCESS";
export const POST_SEAT_ERROR = "POST_SEAT_ERROR";

export const SAVE_BOOK_INFO = "POST_BOOK_INFO";

export const SAVE_CUSTOMER_BEGIN = "SAVE_CUSTOMER_BEGIN";
export const SAVE_CUSTOMER_SUCCESS = "SAVE_CUSTOMER_SUCCESS";
export const SAVE_CUSTOMER_ERROR = "SAVE_CUSTOMER_ERROR";
