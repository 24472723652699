import React, { useEffect } from "react";
import scrollToTop from "../../../utils/scrollToTop";
import ConfirmationForm from "./ConfirmationForm";
import FormWrapper from "./FormWrapper";

function Confirmation() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="confirmation-container">
      <FormWrapper formTitle="Passenger Details">
        <ConfirmationForm />
      </FormWrapper>
    </div>
  );
}

export default Confirmation;
