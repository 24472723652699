import React from "react";
import { logout } from "./redux/actions";

function Logout({ handleClose }) {
  const handleLogout = () => {
    logout();
    handleClose();
  };
  return (
    <div className="logout-modal">
      <div className="title">Are you sure to Logout?</div>
      <div className="button-wrapper">
        <button className="yes" onClick={handleLogout}>
          Yes
        </button>
        <button className="no" onClick={handleClose}>
          No
        </button>
      </div>
    </div>
  );
}

export default Logout;
