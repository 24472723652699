// import axios from "axios";
import { axiosInstance } from "../apis/_axios";

// hero banner
export const getHeroBannerApi = (data) => {
  return axiosInstance().get(`/banner`, data);
};

// site setting
export const getSiteSettingApi = (data) => {
  return axiosInstance().get(`/site-setting`, data);
};

// association
export const getAssociationApi = (data) => {
  return axiosInstance().get(`/association`, data);
};

export const getAssociationSingleApi = (data) => {
  return axiosInstance().get(`/association/${data?.slug}`);
};

// services
export const getServicesApi = (data) => {
  return axiosInstance().get(`/our-service`, data);
};

export const getServicesSingleApi = (data) => {
  return axiosInstance().get(`/our-service/${data?.slug}`);
};
