/* Third party libraries */

/* Third party libraries */
import { Report } from "@mui/icons-material";
import { InputLabel, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Controller } from "react-hook-form";

export const HookFormInput = ({
  name,
  label,
  select = "",
  ref,
  placeholder = "",
  helperText = "",
  defaultValue = "",
  hidden,
  type = "",
  data = [],
  tooltipLabel = "",
  required = false,
  disabled = false,
  control,
  errors,
  height,
  rows,
  multiline
}) => {
  const styles = {
    textfield: {
      width: "100%",
      fontFamily: ["Sofia Sans", "sans-serif", "!important"],

      // color: "red",
      "& fieldset": {
        // border: "1px solid #D6D5D5",
      },
      "& .MuiSvgIcon-root": {
        // color: 'red'
      },
      "&::-webkit-calendar-picker-indicator": {
        display: "none",
        "-webkit-appearance": "none"
      },
      "& .MuiOutlinedInput-root ": {
        color: "red"
      },
      "& .css-jlzsaa-MuiFormHelperText-root": {
        color: "#848793 !important",
        fontSize: "12px !important",
        marginLeft: "0"
      },

      "& .MuiOutlinedInput-input": {
        padding: {
          xs: "10px 10px !important",
          md: "10px 10px !important"
        },
        color: "#999191",
        fontSize: {
          xs: "15px !important",
          md: "13px !important"
        },
        fontWeight: "400 !important",
        fontFamily: ["Sofia Sans", "sans-serif", "!important"]

        // border: ' 1px solid red'
      }

      // "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      //   {
      //     color: "#999999",
      //     fontSize: {
      //       xs: "15px !important",
      //       md: "13px !important",
      //     },
      //     padding: {
      //       xs: "10.9px 14px !important",
      //       md: "6.2px 14px !important",
      //     },
      //   },
    },
    inputLabel: {
      color: "#383751",
      fontWeight: "600 !important",
      fontSize: { xs: "15px", md: "12px" },
      fontFamily: ["Sofia Sans", "sans-serif", "!important"]
    },
    required: {
      color: "#EF4444",
      fontSize: "12px",
      marginLeft: "-3px"
    },
    menuItems: {
      color: "#999999",
      fontSize: {
        xs: "15px !important",
        md: "13px !important"
      }
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" columnGap={0.5} sx={{ paddingBottom: "4px" }}>
        <InputLabel
          // className={classes.inputLabel}
          sx={styles?.inputLabel}>
          {label}
        </InputLabel>
        {/* {required && <span style={styles?.required}>*</span>} */}
        {tooltipLabel && (
          <Tooltip title={<Typography variant="body1">{tooltipLabel}</Typography>}>
            <Report sx={{ color: "#9D9CAF" }} />
          </Tooltip>
        )}
      </Box>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) =>
          !hidden && (
            //           hidden ?  <input
            //           onChange={onChange}
            //           value={value}
            //           ref={ref}
            //           disabled={disabled}
            //           placeholder={placeholder}
            //           type={type} hidden={hidden}        />
            // :
            <TextField
              sx={styles.textfield}
              onChange={onChange}
              value={value ?? ""}
              disabled={disabled}
              InputLabelProps={{ shrink: false }}
              placeholder={placeholder}
              helperText={helperText}
              defaultValue={defaultValue}
              type={type}
              select={select}
              rows={rows}
              multiline={multiline}>
              {data?.map((option) => (
                <MenuItem
                  sx={styles.menuItems}
                  key={option?.value || option?.id}
                  value={option?.value || option?.id}>
                  {option?.label || option.table_readable_name}
                </MenuItem>
              ))}
            </TextField>
          )
        }
      />
      {errors && errors[name] && (
        <p style={{ color: "#FF3B3B", fontSize: "12px" }}>{errors[name]?.message}</p>
      )}
    </>
  );
};
