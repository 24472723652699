import {
  forgotPasswordApi,
  // googleLoginApi,
  loginApi,
  registerApi,
  resetPasswordApi,
  socialLoginApi,
  userRegister,
  verifyPasswordApi
} from "../../../../apis/auth";
import { errorToast, successToast } from "../../../../utils/toast";
import { getUserDetail } from "../../dashboard/Profile/redux/actions";
import * as actions from "./types";

// register
export const register = (data, handleClose) => (dispatch) => {
  dispatch({ type: actions.REGISTRATION_BEGIN });
  registerApi(data)
    .then((res) => {
      dispatch({ type: actions.REGISTRATION_SUCCESS });
      handleClose && handleClose();
      successToast(res.data.message);
    })
    .catch((err) => {
      dispatch({ type: actions.REGISTRATION_ERROR });
      errorToast(err);
    });
};
export const setGlobalUser = () => (dispatch) => {
  try {
    const user = localStorage.getItem("USER");
    const userObj = JSON.parse(user);
    dispatch({
      type: actions.SET_AUTH_USER,
      payload: userObj
    });
  } catch (error) {
    errorToast(error);
  }
};

// login api
export const login =
  (data, setSignInLoading = null, goToDashboard = null) =>
  (dispatch) => {
    dispatch({ type: actions.LOGIN_BEGIN });
    loginApi(data)
      .then((res) => {
        const token = res?.data?.data?.token;
        const refreshToken = res?.data?.data?.refresh_token;
        const user = res?.data?.data?.user;

        // 2. setting token to the localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("USER", JSON.stringify(user));
        setSignInLoading && setSignInLoading(false);
        successToast("Successfully Signed In");
        dispatch(setGlobalUser());
        goToDashboard && goToDashboard();
        dispatch({ type: actions.LOGIN_SUCCESS });
        dispatch(getUserDetail());
      })
      .catch((error) => {
        errorToast(error);
        setSignInLoading && setSignInLoading(false);
        dispatch({ type: actions.LOGIN_ERROR });
      });
  };

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("USER");
  window.location.replace("/");
};

// send forgot password mail
export const forgotPassword = (data, handleRedirect) => (dispatch) => {
  dispatch({
    type: actions.FORGOT_PASSWORD_BEGIN
  });
  forgotPasswordApi(data)
    .then((res) => {
      dispatch({
        type: actions.FORGOT_PASSWORD_SUCCESS
      });
      successToast("Reset Link sent to your email.");
      handleRedirect && handleRedirect();
    })
    .catch((error) => {
      dispatch({
        type: actions.FORGOT_PASSWORD_ERROR
      });
      errorToast(error);
    });
};

// Set new Password with forgot password token or verify email
export const resetPassword = (data, handleRedirect) => (dispatch) => {
  dispatch({
    type: actions.RESET_PASSWORD_BEGIN
  });
  resetPasswordApi(data)
    .then((res) => {
      dispatch({
        type: actions.RESET_PASSWORD_SUCCESS
      });
      handleRedirect && handleRedirect();
      successToast("Password created Successfully");
    })
    .catch((error) => {
      dispatch({
        type: actions.RESET_PASSWORD_ERROR
      });
      errorToast(error);
    });
};

// verification

export const verification = (data) => (dispatch) => {
  dispatch({ type: actions.VERIFICATION_PENDING });
  userRegister(data)
    .then((response) => {
      dispatch({
        type: actions.VERIFICATION_SUCCESS
      });
      successToast(response.data.message);
    })
    .catch((error) => {
      dispatch({ type: actions.VERIFICATION_ERROR });
      errorToast(error);
    });
};

// password reset
export const resetVerifyPassword =
  (data, refetch = () => {}) =>
  (dispatch) => {
    dispatch({
      type: actions.RESET_VERIFY_PASSWORD_BEGIN
    });
    verifyPasswordApi(data)
      .then((res) => {
        refetch && refetch();
        dispatch({
          type: actions.RESET_VERIFY_PASSWORD_SUCCESS
        });
        // successToast("Reset Link sent to your email.");
        successToast(res.data.message);
      })
      .catch((error) => {
        dispatch({
          type: actions.RESET_VERIFY_PASSWORD_ERROR
        });
        errorToast(error);
      });
  };

export const socialLogin = (token, platform, handleClose) => (dispatch) => {
  socialLoginApi(token, platform)
    .then((res) => {
      const token = res?.data?.data?.token;
      const refreshToken = res?.data?.data?.refresh_token;
      const user = res?.data?.data?.user;

      // handleClose();
      // 2. setting token to the localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("USER", JSON.stringify(user));

      successToast("Successfully Signed In");
      dispatch(setGlobalUser());
      dispatch(getUserDetail());
    })
    .catch((error) => {
      errorToast(error);
      handleClose();
    });
};
