// import { ADToBS } from "bikram-sambat-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import useYupValidationResolver from "../../hooks/useYupValidationResolver";
// import CustomNepaliDateNewPicker from "../form/CustomNepaliDateNewPicker/CustomNepaliDateNewPicker";
import CustomNepaliDatePicker from "../form/CustomNepaliDatePIcker/CustomNepaliDatePicker";
// import CustomNepaliPicker from "../form/CustomNepaliPicker/CustomNepaliPicker";
import SearchableSelect from "../form/SearchableSelect/SearchableSelect";
import { searchBus } from "../local/search/redux/actions";

function SearchBar() {
  const location = useLocation();

  const shiftOptions = [
    { label: "All", value: "all" },
    { label: "Day", value: "day" },
    { label: "Night", value: "night" }
  ];
  const defaultValues = {
    from: "",
    to: "",
    // date: ADToBS(new Date()),
    date: "",
    shift: shiftOptions?.[0].value
  };

  const schema = yup.object({
    from: yup.string().required("Origin is required"),
    to: yup.string().required("Destination is required."),
    date: yup.string().required("Travel date is required")
  });

  const resolver = useYupValidationResolver(schema);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({ defaultValues, resolver });

  const { locations } = useSelector((state) => state.search);

  const [locationArray, setLocationArray] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submit = (values) => {
    dispatch(searchBus(values));
    navigate("/search", { state: { ...values } });
  };

  const styles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: 0,
      padding: 0
    })
  };

  useEffect(() => {
    const changedLocation = locations?.map((item) => ({ label: item?.city, value: item?.id }));
    setLocationArray(changedLocation);
  }, [locations]);

  useEffect(() => {
    if (location.state) {
      const strToIntLocation = {
        ...location?.state,
        from: parseInt(location?.state.from),
        to: parseInt(location?.state.to),
        date: location?.state.date
      };
      setValue("from", strToIntLocation.from);
      setValue("to", strToIntLocation.to);
      setValue("date", strToIntLocation.date);
      setValue("shift", strToIntLocation.shift);
    }
  }, []);

  return (
    <div className="search-section">
      <form onSubmit={handleSubmit(submit)}>
        <div className="item-wrap">
          <div className="label">Origin</div>
          <SearchableSelect
            name="from"
            control={control}
            styles={styles}
            options={locationArray}
            errors={errors}
          />
        </div>
        <div className="item-wrap">
          <div className="label">Destination</div>
          <SearchableSelect
            name="to"
            control={control}
            styles={styles}
            options={locationArray}
            errors={errors}
          />
        </div>
        <div className="item-wrap">
          <div className="label">Shift</div>
          <SearchableSelect
            name="shift"
            control={control}
            styles={styles}
            options={shiftOptions || []}
            errors={errors}
          />
        </div>
        <div className="item-wrap">
          <div className="label">Travel Date</div>
          {/* <CustomNepaliPicker type="date" name="date" control={control} errors={errors} /> */}
          <CustomNepaliDatePicker
            defaultDate={location?.state?.date}
            isStyled="true"
            type="text"
            name="date"
            control={control}
            errors={errors}
          />
          {/* <CustomNepaliDateNewPicker  isStyled='true' type="date" name="date" control={control} errors={errors} /> */}
        </div>
        <button>Search</button>
      </form>
    </div>
  );
}

export default SearchBar;
