/*eslint-disable*/
import { Box } from "@mui/material";
import React from "react";
import PrivacyPolicy from "../components/local/privacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <Box>
      <PrivacyPolicy />
    </Box>
  );
};

export default PrivacyPolicyPage;
