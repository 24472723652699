import { Grid } from "@mui/material";
import React from "react";
import ProfileForm from "./ProfileForm";
import ProfilePasswordForm from "./ProfilePasswordForm";

function Profile() {
  return (
    <>
      <div className="wrapper">
        <Grid container className="profile-container">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ProfileForm />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ProfilePasswordForm />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Profile;
