/* eslint-disable */
import { Box, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SkeletonAboutImage from "../../global/loader/skeletonLoader/AboutImage";
import SkeletonArticle from "../../global/loader/skeletonLoader/SkeletonArticle";
import { getServicesSingle } from "../home/redux/actions";
import ServicesHead from "./ServicesHead";

const Services = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();
  const { slug } = useParams();

  const { services_single, services_single_loading } = useSelector((state) => state.home);
  const [servicesSingleData, setServicesSingleData] = useState();

  useEffect(() => {
    services_single?.data && setServicesSingleData(services_single?.data);
  }, [services_single?.data]);

  useEffect(() => {
    const data = {
      slug: slug ?? ""
    };
    dispatch(getServicesSingle(data));
  }, [slug]);

  return (
    <>
      <Box className="profile-container-wrapper">
        {services_single_loading ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <h1>{servicesSingleData?.title}</h1>
        )}
      </Box>

      <Box>
        {services_single_loading ? (
          <div className="container">
            <Grid container sx={{ marginTop: "80px" }}>
              <Grid item lg={6}>
                <SkeletonAboutImage asso />
              </Grid>
              <Grid item lg={6}>
                <SkeletonArticle />
              </Grid>
            </Grid>
          </div>
        ) : (
          <ServicesHead data={servicesSingleData} />
        )}
      </Box>
    </>
  );
};

export default Services;
