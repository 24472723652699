import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import Store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./utils/ErrorBoundary";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}> */}
    {/* <GoogleOAuthProvider
      clientId={"477343287667-f6jidfq7s1va8bufqlutl97c03t5s3is.apps.googleusercontent.com"}> */}
    <GoogleOAuthProvider
      clientId={"968645423209-5h3mvv50s4d0f25soh62gpe5qse45u3i.apps.googleusercontent.com"}>
      <Provider store={Store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
