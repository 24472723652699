import * as actions from "./types";
const defaultState = {
  user: {},
  password_loading: false,
  registration_loading: false,
  login_loading: false,
  verifiedLoading: false,
  reset_password_loading: false,
  verificationStatus: "idle"
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.REGISTRATION_BEGIN:
      return { ...state, registration_loading: true };

    case actions.REGISTRATION_ERROR:
    case actions.REGISTRATION_SUCCESS:
      return { ...state, registration_loading: false };

    case actions.LOGIN_BEGIN:
      return { ...state, login_loading: true };

    case actions.LOGIN_ERROR:
    case actions.LOGIN_SUCCESS:
      return { ...state, login_loading: false };

    case actions.SET_AUTH_USER:
      return { ...state, user: action.payload };

    case actions.FORGOT_PASSWORD_BEGIN:
    case actions.RESET_PASSWORD_BEGIN:
      return { ...state, password_loading: true };

    case actions.FORGOT_PASSWORD_SUCCESS:
    case actions.FORGOT_PASSWORD_ERROR:
    case actions.RESET_PASSWORD_ERROR:
    case actions.RESET_PASSWORD_SUCCESS:
      return { ...state, password_loading: false };

    // verification
    case actions.VERIFICATION_PENDING:
      return { ...state, verifiedLoading: true, verificationStatus: "pending" };
    case actions.VERIFICATION_SUCCESS:
      return { ...state, verificationStatus: "completed" };
    case actions.VERIFICATION_ERROR:
      return { ...state, verifiedLoading: false, verificationStatus: "failed" };

    // password reset verification
    case actions.RESET_VERIFY_PASSWORD_BEGIN:
      return { ...state, reset_password_loading: true };

    case actions.RESET_VERIFY_PASSWORD_SUCCESS:
    case actions.RESET_VERIFY_PASSWORD_ERROR:
      return { ...state, reset_password_loading: false };

    default:
      return state;
  }
};

export default authReducer;
