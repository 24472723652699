import React from "react";
import PaymentDetails from "./PaymentDetails";
import SummaryWrapper from "./SummaryWrapper";
import TravelDetail from "./TravelDetails";

function Summary(props) {
  console.log(
    "total length ==> ",
    props.pre_booked_seat?.length *
      (props.schedule?.route?.sub_route?.price || props.schedule?.route?.price)
  );
  return (
    <>
      <SummaryWrapper wrapperTitle={"Summary"}>
        <TravelDetail
          route={props.schedule?.route || props.schedule?.route?.sub_route}
          date={props.schedule?.departure_date}
          seats={props.pre_booked_seat}
          travel={props.schedule?.bus?.company?.company_name}
          bus_type={props.schedule?.bus?.bus_type?.name}
          bus_no={props.schedule?.bus?.bus_no}
        />

        <PaymentDetails
          perTicketCost={props.schedule?.route?.sub_route?.price || props.schedule?.route?.price}
          totalCost={
            props.pre_booked_seat?.length *
            (props.schedule?.route?.sub_route?.price || props.schedule?.route?.price)
          }
          endDate={props.pre_booked_seat}
          length={props.pre_booked_seat?.length}
          discount={props?.schedule?.route?.sub_route?.discount || props?.schedule?.route?.discount}
        />
      </SummaryWrapper>
    </>
  );
}

export default Summary;
