import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import PageNotFound from "../pages/PageNotFound";
import DashboardRoutes from "./dashboardRoutes";
import HomeRoutes from "./homeRoutes";

const RouteList = () => {
  const routeList = [
    {
      path: "/",
      component: <HomePage />
    },

    ...HomeRoutes,
    ...DashboardRoutes
  ];

  return (
    <Routes>
      {routeList?.map(({ path, component }) => {
        return <Route key={path} path={path} element={component} />;
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteList;
