import { axiosInstance } from "../_axios";

export const getUserDetailApi = () => {
  return axiosInstance().get("/user-detail");
};

export const updateUserDetailApi = (data) => {
  return axiosInstance().post("/user-detail", data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const changePasswordApi = (data) => {
  return axiosInstance().put("/password-change", data);
};
