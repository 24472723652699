import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AboutDescription from "../../global/description/AboutDescription";
// import busimg from "../../../assets/images/busimg.png";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import CustomMasonry from "../../global/customMasonry";

const ServicesHead = ({ data }) => {
  return (
    <section className="section-page-wrapper">
      <div className="container">
        <Grid container>
          <Grid item lg={6} md={6} sm={12}>
            <Box className="associationImageBox">
              <img src={data?.image} alt="" className="associationImage" />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <Box className="busInfoContainer">
              <Typography className="busInfoMainText">
                <AboutDescription description={data?.title} />
              </Typography>
              <Box className="busInfoListBox">
                <div dangerouslySetInnerHTML={{ __html: data?.description }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <hr style={{ margin: "50px 0px", color: "#E5E5EB" }} /> */}
      </div>
      {data?.our_service_gallery?.length !== 0 && (
        <div className="container" style={{ marginTop: "50px" }}>
          <div className="">
            <h1 style={{ fontSize: "30px", marginBottom: "30px" }}>Gallery</h1>
            <div>
              <CustomMasonry>
                {data?.our_service_gallery?.map((item, index) => {
                  return (
                    <div style={{ width: "100%", marginBottom: "2rem" }} key={index}>
                      <ImageCard data={item} images={data?.our_service_gallery} index={index} />
                    </div>
                  );
                })}
              </CustomMasonry>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const ImageCard = ({ data, images, index }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div onClick={openModal} style={{ cursor: "pointer" }}>
        <img
          src={data?.image}
          alt=""
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </div>

      <Lightbox
        open={isModalOpen}
        close={() => closeModal()}
        slides={images?.map((item) => {
          return { src: item?.image };
        })}
        index={index}
        plugins={[Counter]}
      />
    </>
  );
};

export default ServicesHead;
