import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
function Destination({ destinations }) {
  const { locations_main } = useSelector((state) => state.search);
  const [locationsData, setLocationsData] = useState([]);
  useEffect(() => {
    locations_main && setLocationsData(locations_main);
  }, [locations_main]);

  return (
    <div className="destination-wrapper">
      <div className="destination-background"></div>
      <h1 className="destination-title">
        {/* title */}
        Our Popular Destinations
      </h1>

      {/* popular destination */}
      <div className="container">
        <div className="popular-wrapper">
          <Grid container>
            {locationsData?.map((destination) => (
              <Grid
                key={destination?.id}
                item
                xs={6}
                sm={6}
                md={4}
                lg={2}
                sx={{ padding: "20px 0px" }}>
                <p>{destination?.city}</p>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Destination;
